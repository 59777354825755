import { Header } from "@shared/components/Header";
import React, { PropsWithChildren } from "react";

export function Hero(props: PropsWithChildren) {
    return (
        <div className="relative bg-white overflow-hidden">
            <div className="max-w-7xl mx-auto">
                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                    <svg
                        aria-hidden="true"
                        className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white translate-x-1/2"
                        fill="currentColor"
                        preserveAspectRatio="none"
                        viewBox="0 0 100 100"
                    >
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                    <Header />
                    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">{props.children}</main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    alt=""
                    className="h-56 w-full object-cover object-left sm:h-72 md:h-96 lg:size-full"
                    src="/hero_image.jpeg"
                />
            </div>
        </div>
    );
}
